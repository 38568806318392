import React from 'react'
import { Link } from 'gatsby'
import SocialList from './SocialList/SocialList'

const Footer = () => {

  const toRoman = (num, result = '') => {
    const map = {
      M: 1000,
      CM: 900,
      D: 500,
      CD: 400,
      C: 100,
      XC: 90,
      L: 50,
      XL: 40,
      X: 10,
      IX: 9,
      V: 5,
      IV: 4,
      I: 1,
    };
    for (const key in map) {
      if (num >= map[key]) {
        if (num !== 0) {
          return toRoman(num - map[key], result + key);
        }
      }
    }
    return result;
  };

  return (
    <footer className="mt-72 text-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-6 lg:grid-cols-12 pb-32">
          <div className="col-span-6 pb-32 lg:col-span-8">
            <div className="flex flex-col sm:flex-row items-center justify-center lg:justify-start text-14 font-serif space-y-8 space-x-8 sm:space-x-32 lg:space-y-0">
              <span className=" text-14 font-serif">© Repulsive Vision { toRoman(2023) }</span>
              <span>
                <svg className="hidden sm:block" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2.5" r="2" fill="#444444" />
                </svg>
              </span>
              <Link className="navbar-item" to="/privacy-policy">
                Privacy Policy
              </Link>
              <span>
                <svg className="hidden sm:block" width="4" height="5" viewBox="0 0 4 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="2" cy="2.5" r="2" fill="#444444" />
                </svg>
              </span>
              <a href="https://emanzipation.dk/repulsive-vision-necrovictology/" target="_blank" rel="noopener noreferrer">
                Emanzipation Productions
              </a>
            </div>
          </div>
          <div className=" col-start-2 col-span-4 lg:col-span-4">
            <SocialList className="flex flex-wrap space-x-16 xs:space-x-16 justify-center lg:justify-end lg:space-x-24"/>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
