import { graphql, useStaticQuery } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            menu {
              path
              text
            }
            merch {
              title
              url
            }
            social {
              facebook
              bandcamp
              twitter
              instagram
            },
            streaming {
              spotify
              amazon
              apple
              youtube
              deezer
              tidal
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export default useSiteMetadata
