import React, { useEffect, useRef } from "react"
import { Link } from "gatsby"

import { gsap } from "gsap"
import SocialList from '../SocialList/SocialList'

import PropTypes from "prop-types"

import './MobileMenu.css'

const MobileMenu = ({ navItems, open }) => {

    const menu = useRef(null);
    const menuBody = useRef(null);

    useEffect(() => {
        function toggleMenu() {
            if (open) {
                document.body.style.overflow = 'hidden';
                document.body.classList.add('menu-active');
                gsap.to(menu.current, {
                    duration: 0.3,
                    opacity: 1,
                    display: 'block'
                });
                gsap.to(menuBody.current, {
                    duration: 0.3,
                    delay: 0.3,
                    opacity: 1,
                });
            } else {
                document.body.style.overflow = 'visible';
                document.body.classList.remove('menu-active');
                gsap.to(menu.current, {
                    duration: 0.3,
                    opacity: 0,
                    display: 'none'
                });
                gsap.to(menuBody.current, {
                    duration: 0.3,
                    delay: 0,
                    opacity: 0,
                });
            }
        }

        toggleMenu();

    }, [open]);

    return (
        <section className="mobile-menu" ref={menu}>
            <div className="container h-full relative">

                <div class="mobile-menu__body" ref={menuBody}>
                    <nav className="flex flex-col justify-between h-screen w-full">
                        <ul className="mobile-menu__list z-10">
                            {navItems.map((item, index) => {
                                return (
                                    <li className="">
                                        <Link
                                            key={index}
                                            to={item.path}
                                            // exit={exitTransition}
                                            // entry={entryTransition}
                                            className="header-item text-16 no-underline text-white opacity-100 transition hover:opacity-75 mr-70 mb-22 font-medium lg:font-normal"
                                            // activeClassName="text-active"
                                        >
                                            {item.text}
                                        </Link>
                                    </li>
                                )
                            })}
                        </ul>
                        <footer className="z-10">
                            <SocialList className="flex flex-wrap space-x-16 xs:space-x-24 mb-24" />
                        </footer>
                        <div 
                            className="absolute bottom-0 left-0 bg-cover w-full h-1/2 z-0"
                        >
                            {/* <Img fluid={ query.file.childImageSharp.fluid } /> */}
                        </div>
                    </nav>

                </div>

            </div>
        </section>
    )
}

MobileMenu.propTypes = {
    open: PropTypes.bool.isRequired,
}

export default MobileMenu;