import React, { useEffect, useState } from "react"
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import MobileMenu from './MobileMenu'
import useSiteMetadata from '../SiteMetadata'

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

import './Header.css'


const Header = () => {

  const { menu, merch } = useSiteMetadata();
  const [menuOpen, setMenuOpen] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {in: ["logo.png"]}) {
        childImageSharp {
          fluid(maxWidth: 244, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `
  );
  
  useEffect(() => {
    const template = document.getElementById('merch-menu');

    tippy('#menu-item-merch', {
      placement: 'bottom',
      interactive: true,
      content: template.innerHTML,
      allowHTML: true,
      ignoreAttributes: true,
    });
  });

  return (
    <>
    
    <header 
      className="absolute top-0 left-0 w-full z-40 pt-24 lg:pt-16"
      style={{
        background: 'linear-gradient(180deg, #020202 0%, rgba(2, 2, 2, 0.5) 54.88%, rgba(2, 2, 2, 0) 79.83%)'
      }}
    >
      <nav
        className="container mx-auto "
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="grid grid-cols-6 lg:grid-cols-12 items-center gap-8 lg:min-h-176">
          <div className="col-span-2 lg:col-span-6">
            <Link to="/" className="">
              <Img fluid={ data.file.childImageSharp.fluid } alt="Repulsive Vision" className="header__logo" />
            </Link>
          </div>
          <div className="col-span-1 col-start-6 text-right lg:hidden">
            <button className="focus:outline-none" onClick={() => setMenuOpen(menuOpen ? false : true)}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`${!menuOpen ? 'block' : 'hidden'} h-32 w-32 fill-current`}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`${menuOpen ? 'block' : 'hidden'} h-32 w-32 fill-current`}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
              <span className="sr-only">
                {!menuOpen ? 'Open Menu' : 'Close Menu'}
              </span>
            </button>
          </div>
          <div
            id="navMenu"
            className={`hidden lg:block col-span-3 lg:col-span-6`}
          >
            <ul className=" flex flex-wrap justify-between text-white">
              {menu.map((item, index) => {

                function renderMerchMenu(name) {
                  if (name !== "Merch") {
                    return;
                  }
                  return (
                    <div className="hidden" id="merch-menu">
                      <ul className="flex flex-col text-14">
                        {merch.map((item, index) => {
                          return (
                            <li className={`${merch.length === index + 1 ? '' : 'border-b border-gray-500'}`}>
                              <a href={item.url} className="block w-full py-8 px-8 hover:bg-gray-500" target="_blank" rel="noreferrer">{item.title}</a>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  );
                }

                return (
                  <li>
                    <Link className="header-item" to={ item.path } id={`menu-item-${item.text.toLowerCase()}`}>
                      { item.text }
                      { renderMerchMenu(item.text) }
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <MobileMenu navItems={ menu } open={menuOpen} />
    <div className="h-screen w-screen fixed z-10 top-0 left-0 bg-gray-500 hidden">
      <div className="container">
        <ul className="flex flex-col flex-wrap justify-between text-white mt-176">
          <li className="">
            <Link className="header-item" to="/about">
              Band
            </Link>
          </li>
          <li className="">
            <Link className="header-item" to="/tour">
              Tour
            </Link>
          </li>
          <li className="">
            <Link className="header-item" to="/video">
              Video
            </Link>
          </li>
          <li className="">
            <Link className="header-item" to="/music">
              Music
            </Link>
          </li>
          <li className="">
            <Link className="header-item" to="/news">
              News
            </Link>
          </li>
          <li className="">
            <Link className="header-item" to="/merch" id="merch">
              Merch
            </Link>
            <div className="hidden">
              <ul className="flex flex-col text-14">
                {merch.map((item, index) => {
                  return (
                    <li className={`${merch.length === index + 1 ? '' : 'border-b border-gray-500'}`}>
                      <a href={item.url} className="block w-full py-8 px-8 hover:bg-gray-500" target="_blank" rel="noreferrer">{item.title}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </>
  )

}

export default Header
