import React from 'react'
import { Helmet } from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from './Header/Header'
import '../assets/css/main.css';
import './all.css';
import useSiteMetadata from './SiteMetadata'
import { withPrefix } from 'gatsby'

const TemplateWrapper = ({ children, location }) => {
  const { title, description, siteUrl } = useSiteMetadata()

  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/icon.png`}
        />
        <link type="text/plain" rel="author" href="humans.txt"></link>

        <script type="application/ld+json">
          {JSON.stringify([
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": title,
              "url": siteUrl,
            },
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": title,
              "description": description,
              "publisher": {
                "@type": "ProfilePage",
                "name": title
              }
            },
            {
              "@context": "https://schema.org",
              "@type": "MusicGroup",
              "@id": "https://musicbrainz.org/artist/cabf49d8-dceb-4947-b80f-9c7db77b9542",
              "name": title,
              "url": siteUrl,
              "sameAs": [
                "https://www.facebook.com/RepulsiveVision/",
                "https://twitter.com/rvdeathmetal",
                "https://www.instagram.com/repulsive_vision/",
                "https://www.youtube.com/channel/UCDwm0UKaidX8yO9-0yElNTQ"
              ]
            }
          ])}
        </script>
      </Helmet>

      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
